import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home"),
    meta: {
      requiresAuth: true,
      layout: "default-layout",
    },
  },
  // customer
  {
    path: "/ticket-block",
    name: "TicketBlock",
    component: () => import("../views/customer/ticketBlock/TicketBlock"),
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/ticket-page/:cryptoHash",
    name: "TicketPage",
    component: () => import("../views/customer/ticketPage/TicketPage"),
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/s/:titleAlias",
    name: "SaleEventPage",
    component: () => import("../views/customer/ticketPage/SaleEventPage"),
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/poster-page/:cryptoHash",
    name: "posterPage",
    component: () => import("../views/customer/posterPage/PosterPage"),
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/time-slot",
    name: "TimeSlotWidget",
    component: () => import("../views/customer/timeSlot/TimeSlotWidget"),
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/customer/auth/LoginPage"),
    meta: {
      requiresAuth: false,
      layout: "auth-layout",
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPass",
    component: () => import("../views/customer/auth/ForgotPass"),
    meta: {
      requiresAuth: false,
      layout: "auth-layout",
    },
  },
  {
    path: "/reset/:token",
    name: "resetPassword",
    component: () => import("../views/customer/auth/ResetPassword"),
    meta: {
      requiresAuth: false,
      layout: "auth-layout",
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/customer/orders/Orders"),
    meta: {
      requiresAuth: true,
      layout: "default-layout",
    },
  },
  {
    path: "/upgrade/:orderId&:eventId&:lang",
    name: "Upgrade",
    component: () => import("../views/customer/orders/OrderUpgrade"),
    meta: {
      requiresAuth: true,
      layout: "default-layout",
    },
  },

  {
    path: "/event/:eventId/upgrade-order/:orderId/upgrade/:upgradeId&:lang",
    name: "UpgradeOrder",
    component: () => import("../views/registration/checkout/UpgradeOrder"),
    props: true,
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/user-profile",
    name: "GlobalProfile",
    component: () => import("../views/customer/profile/GlobalProfile"),
    meta: {
      requiresAuth: true,
      layout: "default-layout",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/customer/profile/Profile"),
    meta: {
      requiresAuth: true,
      layout: "default-layout",
    },
  },
  {
    path: "/profile-public",
    name: "ProfilePublic",
    component: () => import("../views/customer/profile/ProfilePublic"),
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/event/:eventId/order/:orderId/ticket/:ticketId/stream",
    name: "Stream",
    component: () => import("../views/customer/stream/Stream"),
    meta: {
      requiresAuth: true,
      layout: "stream-layout",
    },
  },
  {
    path: "/event/:eventId/order/:orderId/ticket/:ticketId/schedule",
    name: "Schedule",
    component: () => import("../views/customer/stream/Schedule"),
    meta: {
      requiresAuth: true,
      layout: "stream-layout",
    },
  },
  {
    path: "/event/:eventId/order/:orderId/ticket/:ticketId/stands",
    name: "Stands",
    component: () => import("../views/customer/stream/Stands"),
    meta: {
      requiresAuth: true,
      layout: "stream-layout",
    },
  },
  {
    path: "/event/:eventId/order/:orderId/ticket/:ticketId/stands/:standId",
    name: "StandPage",
    component: () => import("../views/customer/stream/StandPage"),
    meta: {
      requiresAuth: true,
      layout: "stream-layout",
    },
  },
  {
    path: "/event/:eventId/order/:orderId/ticket/:ticketId/shop",
    name: "Shop",
    component: () => import("../views/customer/stream/Shop"),
    meta: {
      requiresAuth: true,
      layout: "stream-layout",
    },
  },
  {
    path: "/materials/:eventId/:orderId/:ticketId",
    name: "Materials",
    component: () => import("../views/customer/materials/Materials"),
    meta: {
      requiresAuth: true,
      layout: "default-layout",
    },
  },
  // registration
  {
    path: "/add-order",
    name: "AddOrder",
    component: () => import("../views/registration/registration/AddOrder"),
    meta: {
      requiresAuth: false,
      layout: "empty-layout",
    },
  },
  {
    path: "/tg-order",
    name: "AddOrderTelegram",
    component: () =>
      import("../views/registration/registration/AddOrderTelegram"),
    meta: {
      requiresAuth: false,
      layout: "clear-layout",
    },
  },
  {
    path: "/p/:cryptoHash",
    name: "AddOrderWithHash",
    component: () => import("../views/registration/registration/AddOrder"),
    meta: {
      requiresAuth: false,
      layout: "empty-layout",
    },
  },
  {
    path: "/seat-selection",
    name: "SeatSelection",
    component: () => import("../views/registration/registration/seatSelection"),
    meta: {
      requiresAuth: false,
      layout: "empty-layout",
    },
  },
  {
    path: "/seat-selection/:eventId",
    name: "FreeSeatSelection",
    component: () => import("../views/registration/registration/FreeSeats.vue"), //freeSeatSelection
    meta: {
      requiresAuth: false,
      layout: "empty-layout",
    },
  },
  {
    path: "/seats/frame/:eventId",
    name: "FreeSeatSelection",
    component: () => import("../views/registration/registration/FreeSeats.vue"), //freeSeatSelection
    meta: {
      requiresAuth: false,
      layout: "clear-layout",
    },
  },
  {
    path: "/zone-selection/:eventId",
    name: "ZoneSelection",
    component: () =>
      import("../views/registration/registration/zoneSelection.vue"),
    meta: {
      requiresAuth: false,
      layout: "empty-layout",
    },
  },
  {
    path: "/oferta/:id",
    name: "Oferta",
    component: () => import("../views/registration/registration/Oferta"),
    props: true,
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  // checkout
  {
    path: "/event/:eventId/pay-order/:orderHash/restore",
    name: "RestoreOrder",
    component: () => import("../views/registration/checkout/RestoreOrder"),
    props: true,
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },

  {
    path: "/event/:eventId/pay-order/:orderHash",
    name: "PayOrder",
    component: () => import("../views/registration/checkout/PayOrder"),
    props: true,
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  // thank you page
  {
    path: "/thank-you-page",
    name: "ThankYouPage",
    component: () => import("../views/registration/tnankyoupage/ThankYouPage"),
    props: true,
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  {
    path: "/thank-you",
    name: "ThankYouPage",
    component: () => import("../views/registration/tnankyoupage/ThankYouPage"),
    props: true,
    meta: {
      requiresAuth: false,
      layout: "default-layout",
    },
  },
  //print
  {
    path: "/print-servers/:token",
    name: "PrintServers",
    component: () => import("../views/customer/printServers/PrintServers.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      hideMenu: true,
      layout: "default-layout",
    },
  },
  {
    path: "/admin-auth/:token",
    name: "AdminAuth",
    component: () => import("../views/AdminAuth.vue"),
    props: true,
    meta: {
      requiresAuth: false,
      layout: "auth-layout",
      hideMenu: true,
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isLoggedIn;
  if (to.meta.requiresAuth && to.name !== 'Login' && !isAuthenticated) {
    sessionStorage.setItem('redirect', window.location.href)
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router